import React from 'react'
import { graphql} from 'gatsby'
import * as PropTypes from 'prop-types'
import Layout from '../layouts'

const propTypes = {
    data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
    constructor(props) {
        super(props);
        const pageData = this.props.data.allContentfulPage.edges.find(p => p.node.id !== '7b820263-73d9-5083-a447-6c9810977c33').node;
        const isThankYouForSignupRequestPage = pageData.id === 'bbf9e9cd-c1de-54f0-9949-e59686f48c48';

        this.state = {isThankYouForSignupRequestPage};
    }

    componentDidMount() {
        if (this.state.isThankYouForSignupRequestPage) {
            typeof window !== "undefined" && window.gtag('event', 'conversion', {'send_to': 'AW-1064231909/uixECMfGreoBEOXHu_sD'});
        }
    }

    render() {
        const pageData = this.props.data.allContentfulPage.edges.find(p => p.node.id !== '7b820263-73d9-5083-a447-6c9810977c33').node
        const homeData = this.props.data.allContentfulPage.edges.find(p => p.node.id === '7b820263-73d9-5083-a447-6c9810977c33').node
        const isHome = pageData.id === '7b820263-73d9-5083-a447-6c9810977c33';
        const seo = pageData.seo || homeData.seo;

        const data = {
            ...this.props,
            pageData,
            homeData,
            isHome,
            seo,
        };

        return (
            <Layout {...data} />
        )
    }
}

PageTemplate.propTypes = propTypes

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
      allContentfulPage(filter: {id: {in: ["7b820263-73d9-5083-a447-6c9810977c33",$id]}}) {
        edges {
          node {
            id
            title
            slug
            contentful_id
            showMediumHeader
            seo {
              focusKeyPhrase
              metaDescription {
                metaDescription
              }
              metaImage {
                description
                file {
                  url
                }
                title
              }
            }
            contentModules {
              ... on ContentfulFooterSection {
                id
                name
                addressLabel
                bottomMenu {
                  includeHomeSections
                  includePageSections
                  items {
                    cssClasses
                    text
                    url
                  }
                  name
                  slug
                  title
                }
                copyright
                emailLabel
                facebook
                logo {
                  image {
                    file {
                      url
                    }
                    title
                  }
                  name
                  slug
                }
                logo2x {
                  image {
                    file {
                      url
                    }
                    title
                  }
                  name
                  slug
                }
                phoneNumber
                phoneNumberLabel
                twitter
                childContentfulFooterSectionAddressTextNode {
                  address
                  childMarkdownRemark {
                    html
                  }
                }
                menus {
                  includeHomeSections
                  includePageSections
                  items {
                    cssClasses
                    text
                    url
                  }
                  name
                  slug
                  title
                }
              }
              ... on ContentfulSubscribeSection {
                id
                button {
                  cssClasses
                  text
                  url
                }
                contentful_id
                message
              }
              ... on ContentfulTextSection {
                id
                contentful_id
                title
                childContentfulTextSectionContentTextNode {
                  content
                  childMarkdownRemark {
                    html
                  }
                }
                childContentfulTextSectionSubTitleTextNode {
                  subTitle
                  childMarkdownRemark {
                    html
                  }
                }
                content {
                    content
                }
              }
              ... on ContentfulNavBar {
                id
                name
                menu {
                  items {
                    cssClasses
                  }
                }
                callToAction {
                  text
                }
                logo {
                  image {
                    file {
                      url
                    }
                  }
                }
                logo2x {
                  image {
                    file {
                      url
                    }
                  }
                }
                stickyLogo {
                  image {
                    file {
                      url
                    }
                  }
                }
                stickyLogo2x {
                  image {
                    file {
                      url
                    }
                  }
                }
                menu {
                  includeHomeSections
                  includePageSections
                  items {
                    url
                    cssClasses
                    text
                  }
                }
              }
              ... on ContentfulAboutUsSection {
                id
                contentful_id
                linkText
                anchor
                title
                childContentfulAboutUsSectionSubTitleTextNode {
                  subTitle
                  childMarkdownRemark {
                    html
                  }
                }
                customers {
                  name
                  slug
                  image {
                    file {
                      url
                    }
                    title
                  }
                }
              }
              ... on ContentfulFeaturesSection {
                id
                linkText
                anchor
                childContentfulFeaturesSectionDescriptionTextNode {
                  description
                  childMarkdownRemark {
                    html
                  }
                }
                title
                features {
                  image {
                    name
                    image {
                      file {
                        url
                      }
                    }
                  }
                  childContentfulFeatureDescriptionTextNode {
                    description
                    childMarkdownRemark {
                        html
                    }
                  }
                  title
                }
              }
              ... on ContentfulHighlightsSection {
                id
                linkText
                anchor
                contentful_id
                childContentfulHighlightsSectionDescriptionTextNode {
                  description
                  childMarkdownRemark {
                    html
                  }
                }
                title
                linkText
                highlights {
                  childContentfulHighlightDescriptionTextNode {
                    description
                    childMarkdownRemark {
                      html
                    }
                  }
                  contentful_id
                  title
                  icon
                  iconClass
                  image {
                    image {
                      file {
                        url
                      }
                      title
                    }
                    name
                  }
                }
              }
              ... on ContentfulTestimonialsSection {
                id
                anchor
                contentful_id
                linkText
                title
                testimonials {
                  childContentfulTestimonialQuoteTextNode {
                    quote
                    childMarkdownRemark {
                      html
                    }
                  }
                  childContentfulTestimonialTitleTextNode {
                    title
                    childMarkdownRemark {
                      html
                    }
                  }
                  companyLogo {
                    file {
                      url
                    }
                    title
                  }
                  companyName
                  image {
                    file {
                      url
                    }
                  }
                  name
                }
              }
              ... on ContentfulTypedMessageSection {
                id
                name
                messages
                contentful_id
              }
              ... on ContentfulBannerSection {
                id
                title
                subTitle
                buttonText
                backgroundImage {
                  image {
                    file {
                      url
                    }
                  }
                  name
                }
                image {
                  name
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`

